import React, { useState, useLayoutEffect, useContext } from 'react'
import { navigate } from "gatsby"

const initialSiteState = {
  pageTitle: null,
  renderLetter: null,
  subscribeRef: null,
  show: null,
  sort: null,
  searchTerm: null,
  filterShowing: false,
  loading: true,
}

export const SiteContext = React.createContext({
  siteState: initialSiteState,
  setSiteState: undefined,
})

export const SiteStore = ({ children }) => {
  const [siteState, setSiteState] = useState(initialSiteState)

  return(
    <SiteContext.Provider value={{
      siteState: siteState,
      setSiteState: setSiteState
    }}>
      {children}
    </SiteContext.Provider>
  )
}

//component that updates the header title
export const HeaderTitle = ({ title, renderLetter }) => {
  const {siteState, setSiteState} = useContext(SiteContext)

  useLayoutEffect(() => {
    setSiteState(prevState => ({
      ...prevState,
      pageTitle: title,
      renderLetter: renderLetter ?? null
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])

  return null
}

// hook to access siteState globally
export const useSiteState = () => {
  const { siteState, setSiteState } = useContext(SiteContext)
  return [siteState, setSiteState]
}

export const useSubscribeRef = () => {
  const {siteState, setSiteState} = useContext(SiteContext)
  const setSubcribeRef = ref => {
    setSiteState(prevState => ({
      ...prevState,
      subscribeRef: ref,
    }))
  }
  return {
    subscribeRef: siteState.subscribeRef,
    setSubscribeRef: setSubcribeRef,
  }
}

export const useFilter = () => {
  const {siteState, setSiteState} = useContext(SiteContext)

  const setSort = value => {
    setSiteState(prevState => ({
      ...prevState,
      sort: value,
    }))
  }

  const setShow = type => {
    setSiteState(prevState => ({
      ...prevState,
      show: type,
    }))
  }

  const setSearchTerm = term => {
    setSiteState(prevState => ({
      ...prevState,
      searchTerm: term,
    }))
  }

  return {
    setSort: setSort,
    setShow: setShow,
    setSearchTerm: setSearchTerm,
    sort: siteState.sort,
    show: siteState.show,
    searchTerm: siteState.searchTerm,
  }
}
